<!-- =========================================================================================
  File Name: 'MyTabs.vue'
  Description: 'Custom tab'
  ----------------------------------------------------------------------------------------
  Author: Nguyen Duy Toan
  Created Date: "2021-02-06"
  Modified Date: "2021-02-06"
========================================================================================== -->
<template>
  <div :class="{'page-container tab-container':isSmall===false}">
    <div class="tab-menu">
      <b-tabs
        :class="{'tab-independence small':isSmall===true, 'inner-tab':innerTab===true}"
        :pills="isSmall===true?false:true"
      >
        <b-tab
          v-for="(tab,index) in listTabs"
          :key="index"
          :active="defaultActive===tab.key"
          :disabled="tab.isDisabled"
          @click="setActive(tab.key)"
        >
          <template

            #title
          >
            <div v-if="isSmall!==true">
              <div
                v-if="tab.isFeatherIcon===false"
                :class="tab.icon"
                class="icon-content mr-half-1"
              />

              <feather-icon
                v-else-if="tab.icon !== undefined || tab.icon !== ''"
                :icon="tab.icon"
                class="icon-content"
                size="14"
              />

              <img
                v-else-if="(tab.icon === undefined || tab.icon === '') && tab.img"
                :src="require(`@/assets/images/ico/${tab.img}`)"
              >
            </div>
            <span class="text-content">{{ $t(tab.title) }}</span>
          </template>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  BImg, BTabs, BTab,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BTabs,
    BTab,
  },
  props: {
    listTabs: {
      type: Array,
      required: true,
    },
    defaultActive: {
      type: String,
      default: null,
    },
    typeCss: {
      type: String,
      default: 'active-outline',
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    innerTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeItem: this.defaultActive,
      link: '@/assets/images/ico/org.svg',
      img: 'org.svg',
    }
  },
  watch: {
    defaultActive(newValue) {
      if (this.activeItem !== newValue) this.activeItem = newValue
    },
  },
  methods: {
    // set tab active
    setActive(menuItem) {
      this.activeItem = menuItem
      this.$emit('setActive', menuItem)
    },

    // check tab is active or not
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
  },
}
</script>

<style lang="scss" >
.mr-half-1 {
     margin-right: 0.5rem;
}
.tab-container {
    margin-bottom: 2rem;
    padding: 15px !important;
    .tab-menu {
      ul {
        margin-bottom: 0px;
        padding-left: 0px;
      }
      ul li {
        list-style-type: none;
        display: inline-block;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .activeOutline {
      border-bottom: 2px solid var(--primary);
    }
    .activeFull {
      color: #fff;
      background-color: var(--primary);
      border-radius: 0.358rem;
    }
    li.activeOutline{
      padding: 2px;
    }
    li.activeFull{
      padding: 9px;
    }
    .nav-pills{
      margin-bottom: 0px !important;
    }
  }
  .tab-pane {
    display: none;
    &.active{
        display: block;
    }
  }
@media (min-width: 375px) and (max-width: 425px) {
  .tab-container {
    .tab-menu {
      display: flex;
      justify-content: space-around;
      ul {
        margin-bottom: 0px;
        padding-left: 0px;
      }
      ul li {
        list-style-type: none;
        display: inline-block;
        margin-right: unset !important;
        a {
          padding: 0.786rem 8px !important;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  .tab-menu{
    .active{
      .icon-content{
        margin-right: 0.5rem !important;
      }
    .text-content{
      display: block !important;
    }

    }
    .text-content{
      display: none;
    }
    .icon-content{
        margin-right: 0px !important;
    }
    li{
      margin-right: 0px !important;
    }
  }
}
</style>
