<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <!-- gender sex. -->
      <label>Giới tính</label>
      <div
        v-if="accountInformation"
        class="demo-inline-spacing"
      >
        <b-form-radio
          v-model="accountInformation.gender"
          :value="true"
          :disabled="disableProp"
        >
          Nam
        </b-form-radio>
        <b-form-radio
          v-model="accountInformation.gender"
          :value="false"
          :disabled="disableProp"
        >
          Nữ
        </b-form-radio>
      </div>
      <template #aside>
        <div id="appImg">
          <div
            v-if="accountInformation.avatar&&accountInformation.avatar!==null&&accountInformation.avatar!==''"
            class="image-input image-input-active d-flex"
          >
            <div
              class="image-preview"
            >
              <img
                class="img-responsive h-100"
                :src="($serverfile+accountInformation.avatar)"
              >
              <b-dropdown
                class="remove-file"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                :disabled="disableProp"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="CameraIcon"
                    size="16"
                    class="icon-camera-hover"
                  />
                </template>
                <b-dropdown-item
                  :disabled="disableProp"
                  @click="openinput"
                >
                  <feather-icon
                    icon="UploadIcon"
                    class="mr-50"
                  />
                  <span>Tải ảnh lên</span>
                </b-dropdown-item>
                <input
                  id="vue-file-upload-input"
                  type="file"
                  class="d-none"
                  :disabled="disableProp"
                  @change="addImage"
                >
                <b-dropdown-item
                  :disabled="disableProp"
                  @click="removeFile(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Xóa ảnh</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div
            v-else
            class="image-input image-input-tbd d-flex"
          >
            <div
              class="image-preview dropzone d-flex justify-content-center align-items-center"
              :disabled="disableProp"
              @click="openinput"
            >
              <feather-icon
                icon="CameraIcon"
                class="icon-camera"
              />
            </div>
            <input
              id="vue-file-upload-input"
              type="file"
              class="d-none"
              :disabled="disableProp"
              @change="addImage"
            >
          </div>
        </div>
      </template>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <!-- name -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Họ và tên người dùng <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
                :custom-messages="customMessages"
              >
                <b-form-input
                  id="basicInput"
                  v-model="accountInformation.name"
                  :state="errors.length > 0 ? false:null"
                  :disabled="disableProp"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <!-- sign-name -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Tên đăng nhập <span
                v-if="isEdit"
                class="required"
              >(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="userName"
                rules="required|min:6"
                :custom-messages="customUsername"
              >
                <b-form-input
                  id="basicInput"
                  v-model="accountInformation.userName"
                  :disabled="!isEdit ? true : false"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <!-- password -->
            <b-form-group>
              <label for="InputHelp">Mật khẩu <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required|min:6"
                :custom-messages="customPassword"
              >
                <b-form-input
                  id="InputHelp"
                  v-model="accountInformation.password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- </div> -->
            </b-form-group>
          </b-col>

          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <!-- password -->
            <b-form-group>
              <label for="InputHelp">Nhập lại mật khẩu <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
                :custom-messages="customRepassword"
              >
                <b-form-input
                  id="InputHelp"
                  v-model="accountInformation.rePassword"
                  @keyup="checkPassword"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="showRepeatPass"
                  class="text-danger"
                > Nhập lại mật khẩu không đúng</small>
              </validation-provider>
              <!-- </div> -->
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Mã người dùng <span
                v-if="isEdit"
                class="required"
              >(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="code"
                rules="required"
                :custom-messages="customCode"
              >
                <b-form-input
                  id="basicInput"
                  v-model="accountInformation.code"
                  :disabled="!isEdit ? true : false"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <!-- email -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Email <span
                v-if="isEdit"
                class="required"
              >(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
                :custom-messages="customEmail"
              >
                <b-form-input
                  id="basicInput"
                  v-model="accountInformation.email"
                  :disabled="!isEdit ? true : false"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <!-- Kiểu người dùng-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
          >
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Kiểu người dùng <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="userTypeId"
                rules="required"
                :custom-messages="customUsertype"
              >
                <v-select
                  v-model="accountInformation.userTypeId"
                  :reduce="userType => userType.id"
                  label="name"
                  placeholder="Lựa chọn kiểu người dùng"
                  :options="dataCombobox || []"
                  :class="{'is-invalid': !!errors.length}"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    {{ $t(option.name) }}
                  </template>
                  <template #selected-option-container="{ option }">
                    <div class="vs__selected">
                      {{ $t(option.name) }}
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    accountInformation: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      customMessages: {
        required: 'Họ và tên là bắt buộc',
      },
      customUsername: {
        required: 'Tên đăng nhập là bắt buộc',
        min: 'Tên đăng nhập ít nhất 6 ký tự',
      },
      customPassword: {
        required: 'Mật khẩu là bắt buộc',
        min: 'Mật khẩu ít nhất 6 ký tự',
      },
      customRepassword: {
        required: 'Nhập lại mật khẩu là bắt buộc',
      },
      customCode: {
        required: 'Mã người dùng là bắt buộc',
      },
      customEmail: {
        required: 'Email là bắt buộc',
        email: 'Không đúng định dạng',
      },
      customUsertype: {
        required: 'Kiểu người dùng là bắt buộc',
      },
      showRepeatPass: false,
      emptyRepassword: false,
    }
  },
  computed: {
    ...mapGetters('userType', ['dataCombobox']),

  },
  methods: {
    checkPassword() {
      if (this.accountInformation.password !== this.accountInformation.rePassword) {
        this.showRepeatPass = true
      } else {
        this.showRepeatPass = false
      }
    },
    ...mapActions('users', ['uploadFile']),
    // account
    openinput() {
      document.getElementById('vue-file-upload-input').click()
    },
    uploadPhoto() {
      this.openinput()
    },
    async addImage(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      const model = {
        files: file,
        isSecure: false,
      }
      const response = await this.uploadFile(model)
      this.accountInformation.avatar = `${response}`
    },
    removeFile(index) {
      this.accountInformation.avatar = null
    },
  },
}
</script>

<style lang="scss" scoped>
#appImg {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  margin-right: 20px;
  .btn {
    padding: 4px !important;
  }
  .image-input {
    padding: 3px;
  }

  .image-preview {
    width: 96px;
    height: 96px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .icon-camera {
    color: white;
    height: 50px;
    width: 50px;
  }
  .dropzone {
    width: 96px;
    height: 96px;
  }
  .dropzone {
    background: #efefef;
    border-radius: 7%;
  }
  .remove-file {
    position: absolute;
    border-radius: 20px;
    margin-top: 28px;
    margin-left: -66px;
    width: 40px;
    height: 40px;
  }
  .remove-file:hover {
    color: white;
    background-color: rgba(130, 134, 139, 0.65);
  }
  .icon-camera-hover {
    width: 28px;
    height: 28px;
    color: rgba(104, 104, 104, 0);
  }
  .icon-camera-hover:hover {
    color: white;
  }
}
</style>
