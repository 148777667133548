<template lang="">
  <div>
    <tree-select
      v-model="selected"
      :disabled="disableProp"
      :options="dataOrganizationUpdated"
      :normalizer="normalizer"
      :load-options="loadOptions"
      :valueFormat="valueFormat"
      :placeholder="$t('Lựa chọn cơ cấu tổ chức')"
      :noChildrenText="$t('không có cư cấu tổ chức con')"
      @input="selectChange"
    >
      <div
        v-if="allowAddNew"
        slot="before-list"
      >
        <div
          class="header-template"
          @click="showAddModal"
        >
          <feather-icon icon="PlusCircleIcon" />
          <span class="btn-text-select">{{ $t('common.add') }}</span>
        </div>
        <div class="border-item-select"></div>
      </div>
    </tree-select>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import TreeSelect, { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// const simulateAsyncOperation = fn => {
//   setTimeout(fn, 1000)
// }
export default {
  components: {
    TreeSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    allowAddNew: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: 'id',
    },
    idMinus: {
      type: String,
      default: '',
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    isMinus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.childrens,
        }
      },
    }
  },
  computed: {
    ...mapGetters('users', ['dataOrganizationChildren', 'dataOrganizationUpdated']),
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== this.selected) {
        this.fetchData()
        this.selected = newValue
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  // created() {
  //   this.fetchData()
  //   // this.doFetchOrganizationChildren(this.id)
  // },
  methods: {
    ...mapActions('users', ['doFetchOrganizationChildren', 'doFetchOrganizationUpdated']),
    async loadOptions({ action, parentNode, callback }) {
      // Typically, do the AJAX stuff here.
      // Once the server has responded,
      // assign children options to the parent node & call the callback.
      if (action === LOAD_CHILDREN_OPTIONS) {
        const model = {
          id: parentNode.id,
          idMinus: this.idMinus,
          isMinus: this.isMinus,
        }
        await this.doFetchOrganizationChildren(model)
        if (this.dataOrganizationChildren.length > 0) {
          this.dataOrganizationChildren.forEach(element => {
            element.childrens = null
          })
        } else { this.dataOrganizationChildren = undefined }
        parentNode.childrens = this.dataOrganizationChildren
      }
    },
    async fetchData() {
      const modelChildren = {
        id: this.value || null,
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchOrganizationUpdated(modelChildren)
      this.dataOrganizationUpdated.forEach(element => {
        element.childrens = null
      })
      this.selected = this.value === '' || this.value === '0' ? null : this.value
    },
    selectChange(value) {
      this.$emit('input', value)
    },
    showAddModal() {
      this.$emit('addNewItem')
    },
  },
}
</script>
<style lang="scss" scoped>
.header-template {
  margin: 0px 10px;
  text-align: left;
  color: #227ff4;
  svg {
    fill: transparent !important;
  }
}

.btn-text-select {
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
}
.border-item-select {
  border-bottom: 1px;
  border-color: #ebe9f1;
  width: 100%;
}
</style>

<style lang="scss">
.is-invalid {
  .vue-treeselect__control {
    border-color: #ea5455;
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
  }
}
</style>
