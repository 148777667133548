<template>
  <div>
    <div
      v-if="hideButon"
      class="d-flex justify-content-between"
    >
      <div class="d-flex">
        <button-component
          class="button-icon"
          content-btn=""
          variant="danger"
          icon-btn="Trash2Icon"
          :disabled="!showBtnMultiDelete"
          @click="deleteItems"
        />
      </div>
      <div class="header-action">
        <search
          class="search-input"
          @change="search"
        />
        <button-component
          class="ml-1"
          content-btn="Thêm nhóm người dùng"
          variant="primary"
          icon-btn="PlusIcon"
          @click="handleModalGroupUser"
        />
      </div>
    </div>

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataUserGroup || []"
      :select-options="{
        enabled: hideButon,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Column -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label ==='Code'"
          class="text-nowrap"
        >
          Mã NHÓM NGƯỜI DÙNG
        </span>
        <span
          v-else-if="props.column.label ==='userTypeName'"
          class="text-nowrap"
        >
          TÊN NHÓM
        </span>
        <span
          v-else-if="props.column.label ==='registeredDate'"
          class="text-nowrap"
        >
          NGÀY GHI DANH
        </span>
        <span
          v-else-if="props.column.label ==='ActionFunction'"
          class="text-nowrap"
        >
          CHỨC NĂNG
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Mã người dùng -->
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            pill
            class="light-code"
          >
            <span class="text-name">{{ $t(props.row.code) }}</span>
          </b-badge>
        </span>

        <!-- Column:Ten nhóm-->
        <span v-else-if="props.column.field === 'userTypeName'">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column:ngày ghi danh -->
        <span v-else-if="props.column.field === 'registeredDate'">
          <span class="text-nowrap">{{ props.row.dateRegister | formatDateToDDMM }}</span>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecordGroup"
      :currentPage="urlQuery.currentPage"
      @pageClick="handlePageClick"
    />

    <!-- modal thêm nhóm người dùng -->
    <modal-user-group
      idModalGroupUser="modalGroup"
      @addGroupUser="addGroupUserHandle"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalUserGroup from '../components/ModalUserGroup.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    ModalUserGroup,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    idEdit: {
      type: String,
      default: '',
    },
    hideButon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'Code',
          field: 'code',
          sortable: false,
        },
        {
          label: 'userTypeName',
          field: 'userTypeName',
          sortable: false,
        },
        {
          label: 'registeredDate',
          field: 'registeredDate',
          sortable: false,
        },
        {
          label: 'ActionFunction',
          field: 'actionFunction',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        officalProfileId: this.idEdit || this.$route.params.id,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      dataListUserGroup: [],
      totalRecord: 0,
    }
  },
  created() {
    this.fetchListUserGroup(this.urlQuery)
  },
  methods: {
    // ...mapActions('users', ['fetchListUserGroup', 'deleteUserGroup', 'doAddUserGroup']),

    // Lấy danh sách nhóm người dùng trong 1 người dùng
    async fetchListUserGroup(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_GROUP_USERS, { params: urlQuery })
      this.dataListUserGroup = data.data.pageLists
      this.totalRecord = data.data.totalRecord
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListUserGroup(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchListUserGroup(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListUserGroup(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Mở modal nhóm người dùng
    handleModalGroupUser() {
      this.$bvModal.show('modalGroup')
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        officalProfileId: this.$route.params.id,
        createBy: '',
        modifiedBy: '',
        portalId: 0,
        validationResult: {},
        messageType: '',
      }
      await axiosApiInstance.post(ConstantsApi.DELETE_GROUP_USERS, payload).then(() => {
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListUserGroup(this.urlQuery)
      }).catch(e => {
        this.$bvToast.toast('Xóa thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thêm nhóm người dùng vào 1 tài khaonr người dùng
    async addGroupUserHandle(val) {
      const payload = {
        officalProfileId: this.$route.params.id,
        groupUsers: val,
      }
      await axiosApiInstance.post(ConstantsApi.ADD_GROUP_USERS, payload).then(() => {
        this.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListUserGroup(this.urlQuery)
        this.$bvModal.hide('modalGroup')
      }).catch(e => {
        this.$bvToast.toast('Thêm thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide('modalGroup')
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.text-name {
  color: #227ff4;
}

.light-code {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
}

.border-status {
  border-radius: 4px;
}

.button-icon {
  .btn-component {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }
}

.search-input {
  width: 400px;
}
</style>
